import {Alignment, Fit, Layout, useRive, UseRiveOptions, UseRiveParameters} from "rive-react";
import React from "react";

const RiveAnimationComponent = () => {

    const params: UseRiveParameters = {
        src: './aulaga_presentacion.riv',
        autoplay: true,
        artboard: 'main',
        animations: ['Animation 1', 'Animation 2'],
        layout: new Layout({
            fit: Fit.Contain,
            alignment: Alignment.Center,
        })
    };

    const options: UseRiveOptions = {
        fitCanvasToArtboardHeight: false,
        useDevicePixelRatio: true
    };

    const { RiveComponent } = useRive(params, options);

    return (
        <>
            <RiveComponent />
        </>
    )
};

export default RiveAnimationComponent;
