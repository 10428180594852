import axios, {AxiosResponse} from "axios";
import {IPlateDetail} from "../models/plate";

//axios.defaults.baseURL = 'https://localhost:44316/api';
axios.defaults.baseURL = 'https://demetrio-apiv1.jdemetriodc.com/api';

const responseBody = (response: AxiosResponse) => response.data;

const request = {
    get: (url: string) => axios.get(url).then(responseBody)
};

const Plates = {
    getPlate: (id: number) : Promise<IPlateDetail> => request.get(`/plate/${id}`),
};

export default Plates;
