import React, {useEffect, useState} from "react";
import weekMenu from "../api/weekMenu";
import {IWeekMenuDetail} from "../models/weekMenu";
import {IPlateForWeekMenu} from "../models/plate";
import PlateModalComponent from "../components/PlateModalComponent";
import {Transition} from "@headlessui/react";

const WeekMenuScreen = () => {

    const titleStyles = "title text-center text-2xl my-2 text-indigo-500";

    const [startersPlates, setStartersPlates] = useState<IPlateForWeekMenu[]>([]);
    const [mainPlates, setMainPlates] = useState<IPlateForWeekMenu[]>([]);
    const [sweetPlates, setSweetPlates] = useState<IPlateForWeekMenu[]>([]);
    const [appetizerPlates, setAppetizerPlates] = useState<IPlateForWeekMenu[]>([]);
    const [firstPlates, setFirstPlates] = useState<IPlateForWeekMenu[]>([]);
    const [secondPlates, setSecondPlates] = useState<IPlateForWeekMenu[]>([]);
    const [thirdPlates, setThirdPlates] = useState<IPlateForWeekMenu[]>([]);
    const [fourthPlates, setFourthPlates] = useState<IPlateForWeekMenu[]>([]);

    const [showModal, setShowModal] = useState(false);
    const [itemSelected, setItemSelected] = useState({
        itemId: 0,
        isTasting: false
    });
    const [currentWeekMenu, setCurrentWeekMenu] = useState<IWeekMenuDetail>({
        id: 0,
        name: '',
        initDate: '',
        finishDate: '',
        isTasting: false,
        price: '',
        plateses: [] || undefined
    });

    function styles(url: any) {
        return {
            backgroundImage: `url(${url})`,
        }
    }

    function distribution() {
        if (currentWeekMenu.plateses !== undefined) {
            currentWeekMenu.plateses.forEach((item) => {
                switch (item.plate.type) {
                    case 'Entrante': {
                        setStartersPlates(startersPlates => startersPlates.concat(item));
                        break;
                    }
                    case 'Postre':  {
                        setSweetPlates(sweetPlates => sweetPlates.concat(item));
                        break;
                    }
                    case 'Principal': {
                        setMainPlates(mainPlates => mainPlates.concat(item));
                        break;
                    }
                    case 'Aperitivo': {
                        setAppetizerPlates(appetizerPlates => appetizerPlates.concat(item));
                        break;
                    }
                    case 'Primer plato': {
                        setFirstPlates(firstPlates => firstPlates.concat(item));
                        break;
                    }
                    case 'Segundo plato': {
                        setSecondPlates(secondPlates => secondPlates.concat(item));
                        break;
                    }
                    case 'Tercer plato': {
                        setThirdPlates(thirdPlates => thirdPlates.concat(item));
                        break;
                    }
                    case 'Cuarto plato': {
                        setFourthPlates(fourthPlates => fourthPlates.concat(item));
                        break;
                    }
                }
            })
        }
    }

    useEffect(() => {
        weekMenu.currentWeekMenu()
            .then((response) => {
                setCurrentWeekMenu(response);
            })
            .catch((error) => console.log(error))
    }, []);

    useEffect(() => {
        distribution()
    }, [currentWeekMenu]);

    return (
        <div className={"w-full min-h-screen h-auto"}>
            <div className={"w-full min-h-screen h-auto flex flex-row"}>
                <div className={"hidden md:block md:w-1/2 h-auto bg-center bg-no-repeat bg-cover"} style={styles("./img/carta.webp")} />
                <div className={"w-full md:w-1/2 h-auto bg-white flex flex-col justify-center px-8 py-8 bg-no-repeat bg-cover"}>
                    <div className={"px-12"} >
                        <h3 className={"text-3xl lg:text-4xl text-center mb-2 text-primary title"}>{currentWeekMenu.name}</h3>
                        {(currentWeekMenu.isTasting) ?
                            <h4 className={"text-base lg:text-lg text-center mb-2"}>{currentWeekMenu.price + '€ con bebida incluida'}</h4>
                            : null
                        }
                        <div className={(currentWeekMenu.plateses !== undefined) ? "flex flex-row justify-center items-center mb-8" : "hidden"}>
                            <p className={"text-blue-500 ml-2 text-sm"}>Pulsa en el nombre de los platos para ver más información</p>
                        </div>

                        <div className={"flex flex-col justify-center items-center"}>
                            <div className={(appetizerPlates.length > 0) ? "flex flex-col" : "hidden"}>
                                {(appetizerPlates.length > 1) ? <p className={titleStyles}>Aperitivos</p> : <p className={titleStyles}>Aperitivo</p>}
                                {(appetizerPlates) ? appetizerPlates.map((plate: IPlateForWeekMenu) => (
                                    <button
                                        key={plate.plate.id}
                                        className={"mb-4 text-base lg:text-xl flex-none hover:transition-transform hover:duration-500 hover:easy-all hover:text-primary"}
                                        onClick={() => {
                                            setShowModal(true);
                                            setItemSelected({
                                                itemId: plate.plate.id,
                                                isTasting: currentWeekMenu.isTasting
                                            })}}>
                                        {plate.plate.name}
                                    </button>
                                )) : null}
                                <hr/>
                            </div>

                            <div className={(startersPlates.length > 0) ? "flex flex-col" : "hidden"}>
                                {(startersPlates.length > 1) ? <p className={titleStyles}>Entrantes</p> : <p className={titleStyles}>Entrante</p>}
                                {(startersPlates) ? startersPlates.map((plate: IPlateForWeekMenu) => (
                                    <button
                                        key={plate.plate.id}
                                        className={"mb-4 text-base lg:text-xl flex-none hover:transition-transform hover:duration-500 hover:easy-all hover:text-primary"}
                                        onClick={() => {
                                            setShowModal(true);
                                            setItemSelected({
                                                itemId: plate.plate.id,
                                                isTasting: currentWeekMenu.isTasting
                                            })}}>
                                        {plate.plate.name}
                                    </button>
                                )) : null}
                                <hr/>
                            </div>

                            <div className={(mainPlates.length > 0) ? "flex flex-col" : "hidden"}>
                                {(mainPlates.length > 1) ? <p className={titleStyles}>Platos principales</p> : <p className={titleStyles}>Plato principal</p>}
                                {(mainPlates) ? mainPlates.map((plate: IPlateForWeekMenu) => (
                                    <button
                                        key={plate.plate.id}
                                        className={"mb-4 text-base lg:text-xl flex-none hover:transition-transform hover:duration-500 hover:easy-all hover:text-primary"}
                                        onClick={() => {
                                            setShowModal(true);
                                            setItemSelected({
                                                itemId: plate.plate.id,
                                                isTasting: currentWeekMenu.isTasting
                                            })}}>
                                        {plate.plate.name}
                                    </button>
                                )) : null}
                                <hr/>
                            </div>

                            <div className={(firstPlates.length > 0) ? "flex flex-col" : "hidden"}>
                                {(firstPlates.length > 1) ? <p className={titleStyles}>Primeros platos</p> : <p className={titleStyles}>Primer plato</p>}
                                {(firstPlates) ? firstPlates.map((plate: IPlateForWeekMenu) => (
                                    <button
                                        key={plate.plate.id}
                                        className={"mb-4 text-base lg:text-xl flex-none hover:transition-transform hover:duration-500 hover:easy-all hover:text-primary"}
                                        onClick={() => {
                                            setShowModal(true);
                                            setItemSelected({
                                                itemId: plate.plate.id,
                                                isTasting: currentWeekMenu.isTasting
                                            })}}>
                                        {plate.plate.name}
                                    </button>
                                )) : null}
                                <hr/>
                            </div>

                            <div className={(secondPlates.length > 0) ? "flex flex-col" : "hidden"}>
                                {(secondPlates.length > 1) ? <p className={titleStyles}>Segundos platos</p> : <p className={titleStyles}>Segundo plato</p>}
                                {(secondPlates) ? secondPlates.map((plate: IPlateForWeekMenu) => (
                                    <button
                                        key={plate.plate.id}
                                        className={"mb-4 text-base lg:text-xl flex-none hover:transition-transform hover:duration-500 hover:easy-all hover:text-primary"}
                                        onClick={() => {
                                            setShowModal(true);
                                            setItemSelected({
                                                itemId: plate.plate.id,
                                                isTasting: currentWeekMenu.isTasting
                                            })}}>
                                        {plate.plate.name}
                                    </button>
                                )) : null}
                                <hr/>
                            </div>

                            <div className={(thirdPlates.length > 0) ? "flex flex-col" : "hidden"}>
                                {(thirdPlates.length > 1) ? <p className={titleStyles}>Terceros platos</p> : <p className={titleStyles}>Tercer plato</p>}
                                {(thirdPlates) ? thirdPlates.map((plate: IPlateForWeekMenu) => (
                                    <button
                                        key={plate.plate.id}
                                        className={"mb-4 text-base lg:text-xl flex-none hover:transition-transform hover:duration-500 hover:easy-all hover:text-primary"}
                                        onClick={() => {
                                            setShowModal(true);
                                            setItemSelected({
                                                itemId: plate.plate.id,
                                                isTasting: currentWeekMenu.isTasting
                                            })}}>
                                        {plate.plate.name}
                                    </button>
                                )) : null}
                                <hr/>
                            </div>

                            <div className={(fourthPlates.length > 0) ? "flex flex-col" : "hidden"}>
                                {(fourthPlates.length > 1) ? <p className={titleStyles}>Cuartos platos</p> : <p className={titleStyles}>Cuarto plato</p>}
                                {(fourthPlates) ? fourthPlates.map((plate: IPlateForWeekMenu) => (
                                    <button
                                        key={plate.plate.id}
                                        className={"mb-4 text-base lg:text-xl flex-none hover:transition-transform hover:duration-500 hover:easy-all hover:text-primary"}
                                        onClick={() => {
                                            setShowModal(true);
                                            setItemSelected({
                                                itemId: plate.plate.id,
                                                isTasting: currentWeekMenu.isTasting
                                            })}}>
                                        {plate.plate.name}
                                    </button>
                                )) : null}
                                <hr/>
                            </div>

                            <div className={(sweetPlates.length > 0) ? "flex flex-col" : "hidden"}>
                                {(sweetPlates.length > 1) ? <p className={titleStyles}>Postres</p> : <p className={titleStyles}>Postre</p>}
                                {(sweetPlates) ? sweetPlates.map((plate: IPlateForWeekMenu) => (
                                    <button
                                        key={plate.plate.id}
                                        className={"mb-4 text-base lg:text-xl flex-none hover:transition-transform hover:duration-500 hover:easy-all hover:text-primary"}
                                        onClick={() => {
                                            setShowModal(true);
                                            setItemSelected({
                                                itemId: plate.plate.id,
                                                isTasting: currentWeekMenu.isTasting
                                            })}}>
                                        {plate.plate.name}
                                    </button>
                                )) : null}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Transition
                appear={true}
                show={showModal}
                enter={"transition-opacity duration-300"}
                enterFrom={"opacity-0"}
                enterTo={"opacity-100"}
                leave={"transition-opacity duration-200"}
                leaveFrom={"opacity-100"}
                leaveTo={"opacity-0"}>

                <PlateModalComponent onChange={setShowModal} item={itemSelected}/>
            </Transition>
        </div>
    );
};

export default WeekMenuScreen;
