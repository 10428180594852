import React, {useEffect, useState} from "react";
import plate from "../api/plate";
import {IPlateDetail} from "../models/plate";
import LoadingComponent from "./LoadingComponent";
import {Interweave} from "interweave";

interface IPlateModalComponent {
    //show: boolean,
    onChange: any,
    item?: {
        itemId?: number,
        isTasting?: boolean
    },
}

const PlateModalComponent = (props: IPlateModalComponent) => {
    const titleStyle = "text-2xl text-indigo-500 font-bold";
    const subTitleStyle = "text-xl text-indigo-500 font-bold";

    const [loading, setLoading] = useState(false);
    const [plates, setPlate] = useState<IPlateDetail>();
    const [showRecipe, setShowRecipe] = useState(false);

    function styles(url: string) {
        return {
            backgroundImage: `url(${url})`,
        }
    }

    function changeShow() {
        if (showRecipe)
            setShowRecipe(false);
        else
            setShowRecipe(true);
    }

    useEffect(() => {
        setLoading(true);
        if (props.item?.itemId)
            plate.getPlate(props.item.itemId)
                .then((response) => setPlate(response))
                .catch((error) => console.log(error))
                .finally(() => setLoading(false))
    }, [props.item]);

    return (
        <>
            <div className={"h-screen w-screen z-10 px-2 flex items-center justify-center fixed top-0 left-0 bg-black bg-opacity-50 "}>
                <div className={"w-full md:w-3/4 lg:w-1/3 z-20 flex flex-col justify-between bg-white opacity-100 rounded-lg"}>
                    <div className={(loading) ? 'block w-full h-full flex flex-col justify-center items-center animate-fade-in p-8' : 'hidden'}>
                        <div className={"w-40 h-40"}>
                            <LoadingComponent />
                        </div>
                    </div>
                    <div className={(loading) ? "hidden" : "block"}>
                        <div className={"h-72 w-full bg-cover p-2 bg-center bg-no-repeat rounded-t-lg"} style={(plates?.image !== null) ? styles(plates?.image as string) : styles('./img/noimage.webp')}/>
                        <div className={(showRecipe) ? "hidden" : "flex flex-col h-auto p-4 w-full"}>
                            <div className={"flex flex-row items-center"}>
                                <p className={titleStyle}>{plates?.name as string}&nbsp;</p>
                                <p className={"font-bold text-xl"}>- {plates?.type as string}</p>
                            </div>
                            <div className={(props.item?.isTasting) ? "hidden" : "flex flex-row mb-2"}>
                                <p className={subTitleStyle}>Precio:&nbsp;</p>
                                <p className={"text-xl"}>{plates?.price as string}€</p>
                            </div>

                            <p className={"overflow-ellipsis overflow-hidden block text-lg"}>{plates?.description as string}</p>
                        </div>
                        <div className={(showRecipe) ? "block h-72 overflow-y-auto p-4 w-full table-auto" : "hidden"}>
                            <Interweave content={plates?.recipe as string} />
                        </div>
                        <div className={"flex flex-row p-4"}>
                            <button className={"mr-2 text-indigo-500"} onClick={() => changeShow()}>Ver información del plato</button>
                            <button className={"ml-2 text-red-500"} onClick={() => {
                                props.onChange(false);
                                setShowRecipe(false)
                            }}>Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};

export default PlateModalComponent;
