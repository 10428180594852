import {ErrorMessage, useFormikContext} from "formik";
import React, {ChangeEvent} from "react";

interface ITextAreaComponent {
    field: string,
    inputStyle?: string,
    setDescription: any
}

const TextAreaComponent = (props: ITextAreaComponent) => {
    const {values} = useFormikContext<any>();

    const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        values[props.field] = e.currentTarget.value;
        props.setDescription(e.currentTarget.value);
    };

    return (
        <textarea className={props.inputStyle} style={{resize: 'none'}} onChange={handleOnChange} />
    )
};

export default TextAreaComponent;
