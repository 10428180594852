import {BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import React from "react";
import CancelReservationConfirmationScreen from "../screen/CancelReservationConfirmationScreen";
import IndexScreen from "../screen/IndexScreen";
import WeekMenuScreen from "../screen/WeekMenuScreen";
import TeamScreen from "../screen/TeamScreen";
import Footer from "../screen/Footer";

const MainRouter = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path={"/"}>
                    <div className="flex flex-col">
                        <IndexScreen />
                        <WeekMenuScreen />
                        <TeamScreen />
                        <Footer />
                    </div>
                </Route>
                <Route exact path={"/cancel/:id&:dayId"}>
                    <CancelReservationConfirmationScreen />
                </Route>
                <Redirect to={"/"} from={"/*"} />
            </Switch>
        </BrowserRouter>
    )
};

export default MainRouter;
