import React, {useEffect, useState} from 'react';
import other from './api/other';
import {IOtherDetail} from "./models/other";
import { OtherContext } from './context/OtherContext';
import {Transition} from "@headlessui/react";
import InformationModalComponent from "./components/InformationModalComponent";
import MainRouter from './routers/MainRouter';

function App() {

    const [showModal, setShowModal] = useState(false);
    const [bodyModal, setBodyModal] = useState({
        title: '',
        message: '',
        buttonText: ''
    });

    const init = (): IOtherDetail => {
        return {
            teamDescription: '',
            urlForm: '',
            phoneNumber: ''
        }
    };

    const [others, setOthers] = useState<IOtherDetail>(init);

    function checkError(error: string) {
        if (error.includes("500") || error.includes("404")) {
            setBodyModal({
                title: 'Error de conexión',
                message: 'Si el problema persiste, vuelva a intentarlo más tarde',
                buttonText: 'Recargar'
            });
            setShowModal(true)
        } else {
            setBodyModal({
                title: 'Error de conexión',
                message: 'Si el problema persiste, vuelva a intentarlo más tarde.',
                buttonText: 'Recargar'
            });
            setShowModal(true)
        }
    }

    useEffect(() => {
        other.get()
            .then((response) => setOthers(response))
            .catch((error) => checkError(error.toString()))
    }, []);

    return (
        <OtherContext.Provider value={{others: others, dispatch: setOthers}}>
            <MainRouter />
            <Transition
                appear={true}
                show={showModal}
                enter={"transition-opacity duration-300"}
                enterFrom={"opacity-0"}
                enterTo={"opacity-100"}
                leave={"transition-opacity duration-200"}
                leaveFrom={"opacity-100"}
                leaveTo={"opacity-0"}>
                <InformationModalComponent title={bodyModal.title} message={bodyModal.message} buttonText={bodyModal.buttonText} onChange={setShowModal} />
            </Transition>
        </OtherContext.Provider>
    );
}

export default App;
