import axios, {AxiosResponse} from "axios";
import { IDay } from "../models/day";

//axios.defaults.baseURL = 'https://localhost:44316/api';
axios.defaults.baseURL = 'https://demetrio-apiv1.jdemetriodc.com/api';

const responseBody = (response: AxiosResponse) => response.data;

const request = {
    get: (url: string) => axios.get(url).then(responseBody)
};

const Days = {
    availableDaysList: () : Promise<IDay[]> => request.get('/day/available'),
};

export default Days;
