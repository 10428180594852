import React, {useContext, useEffect, useState} from "react";
import other from "../api/other";
import {ITeamImage} from "../models/other";
import {OtherContext} from "../context/OtherContext";
import {Interweave} from "interweave";

const TeamScreen = () => {

    function styles(url: any) {
        return {
            backgroundImage: `url(${url})`,
        }
    }

    const [teamImage, setTeamImage] = useState<ITeamImage>({
        firstImage: '',
        secondImage: '',
        thirdImage: '',
        quarkImage: '',
        fifthImage: '',
        sixthImage: ''
    });

    const {others} = useContext(OtherContext);

    useEffect(() => {
        other.getImage().then((response) => setTeamImage(response)).catch((error) => console.log(error));
    }, []);

    return (
        <div className={"w-full h-auto min-h-screen flex flex-row flex-wrap"}>
            <div className={"w-full md:w-1/2 h-auto md:h-full overflow-y-auto bg-white flex flex-col justify-center px-8"}>
                <div className={"px-4 py-4 md:px-12 md:py-12"}>
                    <h3 className={"text-2xl lg:text-4xl text-primary mb-8 title"}>Nuestro equipo</h3>

                    <Interweave content={"<div class='text-sm lg:text-lg'>" + others.teamDescription + "</div>"}/>
                </div>
            </div>
            <div className={"w-full md:w-1/2 h-96 md:h-auto flex flex-row flex-wrap"}>
                <div style={styles(teamImage.firstImage)} className={"w-1/2 bg-no-repeat bg-center lg:bg-top bg-cover"}/>
                <div style={styles(teamImage.secondImage)} className={"w-1/2 bg-no-repeat bg-center lg:bg-top bg-cover"}/>
                <div style={styles(teamImage.thirdImage)} className={"w-1/2 bg-no-repeat bg-center lg:bg-top bg-cover"}/>
                <div style={styles(teamImage.quarkImage)} className={"w-1/2 bg-no-repeat bg-center lg:bg-top bg-cover"}/>
                <div style={styles(teamImage.fifthImage)} className={"w-1/2 bg-no-repeat bg-center lg:bg-top bg-cover"}/>
                <div style={styles(teamImage.sixthImage)} className={"w-1/2 bg-no-repeat bg-center lg:bg-top bg-cover"}/>
            </div>
        </div>
    );
};

export default TeamScreen;
