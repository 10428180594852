import axios, {AxiosResponse} from "axios";
import {IWeekMenuDetail} from "../models/weekMenu";

//axios.defaults.baseURL = 'https://localhost:44316/api';
axios.defaults.baseURL = 'https://demetrio-apiv1.jdemetriodc.com/api';

const responseBody = (response: AxiosResponse) => response.data;

const request = {
    get: (url: string) => axios.get(url).then(responseBody)
};

const WeekMenu = {
    currentWeekMenu: () : Promise<IWeekMenuDetail> => request.get('/weekmenu/current')
};

export default WeekMenu;
